import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './../assets/images/logo-login.png';
import hexagon from './../assets/images/hexagon.png';

const Login = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token !== null) {
            navigate('/dashboard');
        }
    }, [navigate]);

    return (
        <>
            <div className="w-100">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-8 page-wrapper-left-outer col-md-7">
                            <div className="page-wrapper hex-wrapper">
                                <div className="hex-row d-flex first" style={{marginTop: "-122px"}}>
                                    <div className="hexagon"></div>
                                    <div className="hexagon"></div>
                                    <div className="hexagon double"></div>
                                    <div className="hexagon"></div>
                                </div>
                                <div className="hex-row second d-flex">
                                    <div className="hexagon double"></div>
                                    <a href="https://frc-parents.stchrisapps.com/" className="hexagon no-border academic" tabIndex="0">
                                        <div className="competencies-label">ACADEMIC</div>
                                    </a>
                                    <a href="https://frc-parents.stchrisapps.com/" className="hexagon no-border metacognition" tabIndex="0">
                                        <div className="competencies-label">METACOGNITION</div>
                                    </a>
                                    <div className="hexagon"></div>
                                </div>
                                <div className="hex-row third d-flex">
                                    <div className="hexagon"></div>
                                    <a href="https://frc-parents.stchrisapps.com/" className="hexagon no-border wellbeing" tabIndex="0">
                                        <div className="competencies-label">WELLBEING</div>
                                    </a>
                                    <div className="hexRow">
                                        <div className="hexItem">
                                            <img src={hexagon} className="img-fluid w-100" alt="heaxagon"/>
                                        </div>
                                    </div>
                                    <a href="https://frc-parents.stchrisapps.com/" className="hexagon no-border service" tabIndex="0">
                                        <div className="competencies-label">SERVICE</div>
                                    </a>
                                </div>
                                <div className="hex-row second d-flex">
                                    <div className="hexagon double"></div>
                                    <a href="https://frc-parents.stchrisapps.com/" className="hexagon no-border digital" tabIndex="0">
                                        <div className="competencies-label">DIGITAL</div>
                                    </a>
                                    <a href="https://frc-parents.stchrisapps.com/" className="hexagon no-border enterprise" tabIndex="0">
                                        <div className="competencies-label">ENTERPRISE</div>
                                    </a>
                                    <div className="hexagon"></div>
                                </div>
                                <div className="hex-row last d-flex">
                                    <div className="hexagon"></div>
                                    <div className="hexagon"></div>
                                    <div className="hexagon double"></div>
                                    <div className="hexagon"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4  col-md-5 px-xl-5 px-md-4">
                            <div className="page-wrapper-right py-5 w-100 mx-auto ">
                                <a href="https://frc-parents.stchrisapps.com/" className="login-logo d-block mb-5">
                                    <img src={logo} className="img-fluid" alt="Logo"/>
                                </a>
                                <h2 className="text-primary fw-bold mb-4"> St Chris Future Ready Curriculum!</h2>
                                <p className="text-dark text-md mb-5">Welcome to St Chris Future Ready Curriculum. Login
                                    using your
                                    isams account, you can access your child's lessons, homework assignments, and
                                    progress reports
                                    all in one place.</p>
                                <a href="https://parentapp.stchrisapps.com/isams-login/frc-production"
                                   className="login-button rounded position-relative w-100 d-block border-0 text-white d-flex align-items-center justify-content-center">
                                    <span className="fw-bold text-uppercase"> Login with isams</span>
                                    <div
                                        className="login-icon rounded-circle me-3 end-0 position-absolute d-flex align-items-center justify-content-center ms-auto">
                                        <i className="fa fa-sign-in-alt text-sm"></i>
                                    </div>
                                </a>
                                <p className="text-dark text-18 fw-bold mt-5 pt-md-3">Learn Competencies</p>
                                <button
                                    className="login-button-light mb-2 text-center d-block w-100 rounded fw-bold">All
                                    Competencies
                                </button>
                                <button
                                    className="login-button-light  mb-2 text-center d-block w-100 rounded fw-bold">Individual
                                    Competencies
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
