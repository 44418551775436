import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import ParentView from './components/ParentView';
import Analytics from './components/Analytics';
import User from './components/User';
import Login from './components/Login';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/source-sans.css';
import './assets/css/fontawesome/css/all.min.css';
import './assets/css/style.css';

const App = () => {
    const isAuthenticated = !!localStorage.getItem('token');

    return (
        <Router>
            <div>
                <Routes>
                    <Route path="/dashboard" element={isAuthenticated ? <ParentView /> : <Navigate to="/" />} />
                    <Route path="/analytics" element={isAuthenticated ? <Analytics /> : <Navigate to="/" />} />
                    <Route path="/isams-callback" element={<User />} />
                    <Route path="/" element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />} />
                </Routes>
            </div>
        </Router>
    );
};
export default App;
