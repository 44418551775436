import React, {useEffect, useState} from 'react';
import parse from 'html-react-parser';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import logo from './../assets/images/logo.png';
import {ClassicSpinner} from "react-spinners-kit";

const ParentView = () => {
    const [students, setStudents]                       = useState([]);
    const [studentData, setStudentData]                 = useState(null);
    const [studentUnitData, setStudentUnitData]         = useState(null);
    const [yearGroupsData, setYearGroupsData]           = useState(null);
    const [selectedStudentId]                           = useState("");
    const [selectedOptionId]                            = useState("");
    const [weekData, setWeekData]                       = useState(null);
    const [selectedWeek, setSelectedWeek]               = useState(null);
    const [subjectsData, setSubjectsData]               = useState([]);
    const [competenciesData, setCompetenciesData]       = useState([]);
    const [selectedTab, setSelectedTab]                 = useState(0);
    const [studentYear, setStudentYear]                 = useState(null);
    const [studentSubjectsData, setStudentSubjectsData] = useState(null);
    const [searchQuery, setSearchQuery]                 = useState('');
    const [selectedOptions, setSelectedOptions]         = useState([]);
    const [selectedSubjects, setSelectedSubjects]       = useState([]);
    const [isChecked, setIsChecked]                     = useState(false);
    const [eventTitle, setEventTitle]                   = useState('');
    const [isExpanded, setIsExpanded]                   = useState(1);
    const [isDropdownOpen, setIsDropdownOpen]           = useState(false);
    const [isLoading, setIsLoading]                     = useState(true);

    const navigate = useNavigate();

    const getStudentInfo = async (tabId, year, yearGroups) => {
        setIsLoading(true);
        setSelectedWeek(null);
        setSelectedTab(tabId);
        setStudentYear(year);

        const lastCharacter = year.replace(" ", "");

        if (yearGroups && yearGroups.year_groups && yearGroups.year_groups.length > 0) {
            yearGroups.year_groups.some((year) => {
                if (year.title === lastCharacter) {
                    const id = year.id;
                    try {
                        axios.get(`https://frc.stchrisapps.com/api/units/year-groups/${id}`).then(response => {
                            console.log(response,'umer')
                            setStudentData(response.data.data);
                            setStudentUnitData(response.data.data.units);
                            setStudentSubjectsData(response.data.data.subjects);
                            setSelectedWeek("ALL");
                            setIsLoading(false);
                        });
                    } catch (error) {
                        if (error.status) {
                            if (error.status === 401) {
                                localStorage.removeItem('token');
                                window.location.href = '/';
                            }
                        }
                        console.error(error);
                        setIsLoading(false);
                    }
                    return true;
                }
                return false;
            });
        } else {
            setSelectedWeek("ALL");
            setIsLoading(false);
        }
    };

    const fetchData = async () => {
        try {
            // const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYmM2YjJhYjQ4OTBkNmQ3NzIxYWYxY2ExYTQ4OWRjZDhjYzQ0MjJjMTg0NTgzNDQxNGE2ZTE0MDc1YWY1OTliODhlMTRiYzI3M2ZkNmE4NjUiLCJpYXQiOjE2ODc4MDQ2MjMsIm5iZiI6MTY4NzgwNDYyMywiZXhwIjoxNzE5NDI3MDIzLCJzdWIiOiIyMDM2Iiwic2NvcGVzIjpbXX0.jt7uG1PBRF2qa6ZhD1g2fNYkXsizZLBDr1mGDzn0hViJkNbq6LdAKF36x-ZQoNAMcOJWabCV9AIhuo1sfUgZS51CDeUlpiDeSiJZvQpDRa2OjcaxZhHN0gPbH-aPOEWloMQi4gTD_ByibE15yl0YGimpSpCrmdL2UCfWvsis5RbmjwG9t33tcJdhgoj2YP9rsw55AhHAIH79F5qQGEK5SA6Yy1vA3QjhEafqevuxS4wHOaaPHFTVwW2YMp0EgApJKxHf7PgUEGmTgBTm42f13lEDlF41Htqd6PlSg6G7EB1WfG207ZFdxubGh1a_7nWKc2M6vTi7rcTUDLWISqPEShZsMYN4wHobIVMvi9OMm4w5IEVlOD9hC4Q17xYohuzfAewkSiHEMKcDgYKwJXOiULAh3D-A4aVHZ-Bn9W0x9ssKxUgimD0HZOdFT521iTsiyd2QuapO6AkAN_EtscEQ8mbWRRR2Q2ol8JeBXC8hEmBANFOsBreD040VWe0iG96gGmbVyHURoQde03EXdgUzjvAIk-ChvcIw6EoTursi-QENHB49LWhcczDmh_CDeprYwuSe612fTw_BNOidPd3JmJ8sPcOQg0ZjgcIhQmeJCHJg1LOUERnn5j1aCmvVCdJjvKAsTTQnjVV2wfc5_HyUIUl1V3ehMQGc0hMeY-tliXU"
            const token = localStorage.getItem('token');

            if (token === null) {
                navigate('/');
            } else {
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                };

                const [subjectsResponse, weeksResponse, competenciesResponse, yearGroupsResponse, studentsResponse] = await Promise.all([
                    axios.get('https://frc.stchrisapps.com/api/metadata/subjects'),
                    axios.get('https://frc.stchrisapps.com/api/metadata/weeks'),
                    axios.get('https://frc.stchrisapps.com/api/metadata/opportunities'),
                    axios.get('https://frc.stchrisapps.com/api/metadata/year-groups'),
                    axios.get('https://parentapp.stchrisapps.com/api/students', {headers}),
                ]);

                setSubjectsData(subjectsResponse.data.data.subjects);
                setWeekData(weeksResponse.data);
                setCompetenciesData(competenciesResponse.data);
                setYearGroupsData(yearGroupsResponse.data.data);
                setStudents(studentsResponse.data.data);

                if (studentsResponse.data.data.length > 0) {
                    getStudentInfo(0, studentsResponse.data.data[0].year , yearGroupsResponse.data.data);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleFilters = (weekLabel, searchValue = '', searchCompetencies = [], searchSubjects = []) => {
        setSearchQuery(searchValue);

        if (!studentData || !studentData.units) {
            setStudentUnitData([]);
            setIsLoading(false);
            return;
        }

        let filteredData = studentData.units;

        if (weekLabel === 'ALL') {
            setSelectedWeek(weekLabel);
            if (searchValue) {
                filteredData = filteredData.filter((unit) => {
                    return (
                        unit?.subjects[0]?.title.toLowerCase().includes(searchValue?.toLowerCase()) ||
                        unit?.topic?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                        unit?.learning_objectives?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                        unit?.resources[0]?.title?.toLowerCase().includes(searchValue?.toLowerCase())
                    );
                });
            }

            if (selectedSubjects.length !== 0) {
                filteredData = filteredData.filter((unit) => {
                    return selectedSubjects?.includes(unit?.subjects[0]?.title);
                });
            }

            if (selectedOptions.length !== 0) {
                filteredData = filteredData.filter((unit) => {
                    return unit.opportunities.some((opportunity) => {
                        return selectedOptions.includes(opportunity.title);
                    });
                });
            }

            setStudentUnitData(filteredData);
        } else {
            setSelectedWeek(weekLabel);

            if (searchValue) {
                filteredData = filteredData.filter((unit) => {
                    return (
                        unit?.subjects[0]?.title?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                        unit?.topic?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                        unit?.learning_objectives?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                        unit?.resources[0]?.title?.toLowerCase().includes(searchValue?.toLowerCase())
                    );
                });
            }

            if (weekLabel) {
                filteredData = filteredData.filter((unit) => {
                    return unit?.formatted_from_week === weekLabel;
                });
            }

            if (selectedSubjects.length !== 0) {
                filteredData = filteredData.filter((unit) => {
                    return selectedSubjects?.includes(unit?.subjects[0]?.title);
                });
            }

            if (selectedOptions.length !== 0) {
                filteredData = filteredData.filter((unit) => {
                    return unit?.opportunities?.some((opportunity) => {
                        return selectedOptions?.includes(opportunity?.title);
                    });
                });
            }

            if (filteredData.length !== 0 && filteredData) {
                if (filteredData) {
                    setEventTitle(filteredData[0]?.event_title);
                }
            }

            setStudentUnitData(filteredData);
        }
    };


    useEffect(() => {
        handleFilters(selectedWeek, searchQuery, selectedSubjects, selectedOptions);
    }, [selectedWeek, searchQuery, selectedSubjects, selectedOptions]);

    const handleRemoveOption = (optionToRemove, type) => {
        if (type === 'competency') {
            const index = selectedOptions.indexOf(optionToRemove);
            if (index !== -1) {
                const updatedOptions = [...selectedOptions];
                updatedOptions.splice(index, 1);
                setSelectedOptions(updatedOptions);
            }
        }

        if (type === 'subject') {
            const index = selectedSubjects.indexOf(optionToRemove);
            if (index !== -1) {
                const updatedOptions = [...selectedSubjects];
                updatedOptions.splice(index, 1);
                setSelectedSubjects(updatedOptions);
            }
        }
    };

    const getSubjectName = (id) => {

        const subjectsArray = Object.values(studentData.subjects);
        const foundSubject = subjectsArray.find((subject) => subject.id === id);
        if (foundSubject) {
            return foundSubject.title;
        } else {
            return "N/A";
        }
    };


    const getResourceInfo = (id, type) => {

        const foundResource = studentData.resources.find(resource => resource.unit_id === id);
        if (foundResource) {
            if (type === 'url') {
                return foundResource.link;
            }
            if (type === 'title') {
                return foundResource.title;
            }
        } else {
            return "N/A";
        }
    };

    const getOppurtunityInfo = (id, type) => {

        const foundOppurtunity = competenciesData.data?.opportunities?.find(oppurtunity => oppurtunity.id === id);
        if (foundOppurtunity) {
            if (type === 'color') {
                return foundOppurtunity.color;
            }
            if (type === 'title') {
                return foundOppurtunity.title;
            }
        } else {
            return "N/A";
        }
    };

    const getWeekInfo = (from_week) => {

        const foundWeek = studentData.weeks?.find(week => week.id === from_week);
        if (foundWeek) {
            return foundWeek.label;
        } else {
            return "N/A";
        }
    };

    useEffect(() => {
        if (selectedSubjects.length) {
            handleFilters(selectedWeek, searchQuery, selectedOptions, selectedSubjects)
        }
    }, [selectedSubjects]);

    const addSubjectSearch = (searchSubjects = []) => {

        const subjectsIndex = selectedSubjects.indexOf(searchSubjects);

        if (subjectsIndex === -1) {
            setSelectedSubjects(prevSelectedSubjects => prevSelectedSubjects.concat(searchSubjects));
        } else {
            const updatedSubjects = [...selectedSubjects];
            updatedSubjects.splice(subjectsIndex, 1);
            setSelectedSubjects(updatedSubjects);
        }
    }

    useEffect(() => {
        if (selectedOptions.length) {
            handleFilters(selectedWeek, searchQuery , selectedOptions, selectedSubjects)
        }
    }, [selectedOptions]);

    const addCompetencySearch = (searchCompetencies = []) => {

        const index = selectedOptions.indexOf(searchCompetencies);
        if (index === -1) {
            setSelectedOptions(prevSelectedOptions => prevSelectedOptions.concat(searchCompetencies));
        } else {
            const updatedOptions = [...selectedOptions];
            updatedOptions.splice(index, 1);
            setSelectedOptions(updatedOptions);
        }

        handleFilters(selectedWeek, searchQuery , selectedOptions, selectedSubjects)
    }

    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.href = '/';
    };


    return (
        <>
            <div style={{pointerEvents: isLoading ? 'none' : 'all'}}>
                <div>
                    {isLoading && (
                        <div style={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: 9999,
                                pointerEvents: 'none'
                            }}>
                            <ClassicSpinner size={40} color="#4D8A9A" loading={isLoading}/>
                        </div>
                    )}
                </div>
                <nav className="navbar main-navbar py-0 navbar-expand-lg bg-white">
                    <div className="container-fluid">
                        <a className="navbar-brand main-logo pe-3" href="https://frc-parents.stchrisapps.com">
                            <img src={logo} alt="Logo"/>
                        </a>
                        <a href="https://frc-parents.stchrisapps.com"
                           className="fw-semibold text-base text-primary main-navbar-link pt-2 fw-bold px-lg-3 px-2">Week
                            Lessons</a>
                        <div className="collapse navbar-collapse justify-content-lg-end" id="navbarTogglerDemo02">
                            <div className="d-lg-flex ms-xl-3 mx-lg-2 align-items-center ps-xl-3 ps-lg-2" role="search">
                                <div className="d-flex align-items-center ps-2 ms-3 dropdown">
                                    <button href="https://frc-parents.stchrisapps.com/dashboard"
                                            className="btn d-flex align-items-center  arrow-none btn btn-danger custom-btn"
                                            type="button" aria-expanded="false"><i className="fa fa-home me-2"></i>
                                        Dashboard
                                    </button>
                                </div>
                                <div
                                    className="profile-dropdown d-lg-flex d-none align-items-center ps-2 ms-3 dropdown">
                                    <button
                                        className="btn d-flex align-items-center arrow-none border-0 bg-transparent p-0 dropdown-toggle"
                                        type="button"
                                        onClick={toggleDropdown}
                                        aria-expanded={isDropdownOpen}>
                                        <i className="fa fa-chevron-down text-grey-200 ms-2 text-sm"></i>
                                    </button>
                                    <ul className={`dropdown-menu ${isDropdownOpen ? 'show' : ''} dropdown-menu-start`}>
                                        <li>
                                            <a className="dropdown-item text-md"
                                               href="https://frc-parents.stchrisapps.com/dashboard">
                                                Dashboard
                                            </a>
                                        </li>
                                        <div className="dropdown-divider"></div>
                                        <li>
                                            <a className="dropdown-item text-md text-danger" href="#" onClick={handleLogout}>
                                                <i className="fa fa-sign-out text-danger"></i> Logout
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>


                <div className="bg-light py-3">
                    <ul className="nav nav-tabs main-tabs" id="myTab" role="tablist">
                        {students.map((student, index) => (
                            <li className={`nav-item ${index === 0 ? ' ms-md-5 ms-3' : ''}`}
                                role="presentation"
                                key={`${student.id}-${index}`}>
                                <button
                                    className={`nav-link text-base d-flex align-items-center justify-content-center fw-semibold ${selectedTab === index ? 'active' : ''}`}
                                    id={`${student.first_name}-tab`}
                                    data-bs-toggle="tab"
                                    data-bs-target={`#${student.first_name}-tab-pane`}
                                    type="button"
                                    role="tab"
                                    aria-controls={`${student.first_name}-tab-pane`}
                                    aria-selected="false"
                                    onClick={() => getStudentInfo(index, student.year, yearGroupsData)}>
                                    <div className="icon-28 rounded-circle me-2">
                                        <img src={student.photo} className="rounded-circle img-fluid" alt="user"/>
                                    </div>
                                    {student.first_name}
                                </button>
                            </li>
                        ))}
                    </ul>
                    <div className="px-md-5 d-md-flex pt-3 px-3">
                        <div className="d-flex mb-md-0 mb-2">
                            <div>
                                <select
                                    className="text-sm truncate-select x-truncate tabs-input pe-2 fw-semibold me-md-2 border-0 bg-transparent text-primary"
                                    value={selectedStudentId}
                                    onChange={(e) => addSubjectSearch(e.target.value)}>
                                    <option value="">Subjects</option>
                                    {subjectsData && subjectsData.map((subject, index) => (
                                        <option key={`${subject.id}-${index}`} value={subject.title}>
                                            {subject.title}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <select
                                    className="text-sm tabs-input px-2 fw-semibold border-0 bg-transparent text-primary"
                                    value={selectedStudentId}
                                    onChange={(e) => addCompetencySearch(e.target.value)}>
                                    <option value="">FRC Competencies</option>
                                    {competenciesData.data &&
                                    competenciesData.data.opportunities &&
                                    competenciesData.data.opportunities.map((competency, index) => (
                                        <option key={`${competency.id}-${index}`} value={competency.title}>
                                            {competency.title}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="d-md-flex ms-md-5 ">
                            <div className="form-check d-flex">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    checked={isChecked}
                                    onChange={(e) => setIsChecked(e.target.checked)}/>
                                <label className="form-check-label ms-2 pt-1 text-sm text-black fw-semibold me-md-3 mb-md-0 mb-2"
                                    htmlFor="flexCheckDefault">
                                    Assessment Modes
                                </label>
                            </div>
                            <input
                                className="custom-input me-md-2 tabs-input-search mb-md-0 mb-2"
                                type="text"
                                placeholder="Search"
                                value={searchQuery}
                                onChange={(e) => handleFilters(selectedWeek, e.target.value)}/>

                            <div className="btn-group me-xl-3 me-lg-2 btn-expand-group" role="group"
                                 aria-label="Basic example">
                                <button type="button"
                                        className={`btn custom-btn ${isExpanded === 1 ? 'custom-btn-active' : ''}`}
                                        onClick={() => setIsExpanded(1)}><i className="fa fa-expand"></i>
                                </button>
                                <button type="button"
                                        className={`btn custom-btn ${isExpanded === 2 ? 'custom-btn-active' : ''}`}
                                        onClick={() => setIsExpanded(2)}><i className="fa fa-angles-down"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="px-md-5 d-md-flex">
                        {selectedOptions.map((option, index) => (
                            <div className="custom-tags mt-2 text-dark fw-semibold d-flex align-items-center bg-white"
                                 key={index}> {option}
                                <i
                                    className="fa fa-times text-primary ms-2"
                                    onClick={() => handleRemoveOption(option, 'competency')}
                                ></i>
                            </div>
                        ))}

                        {selectedSubjects.map((subject, index) => (
                            <div className="custom-tags mt-2 text-dark fw-semibold d-flex align-items-center bg-white"
                                 key={index}>{subject}
                                <i
                                    className="fa fa-times text-primary ms-2"
                                    onClick={() => handleRemoveOption(subject, 'subject')}
                                ></i>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="custom-scroll-nav d-flex align-items-center bg-white">
                    <div className={`text-grey-200 fw-semibold text-md px-3 custom-scroll-nav-item ${selectedWeek === "ALL" ? ' active' : ''}`}
                        onClick={() => handleFilters("ALL")}>ALL
                    </div>
                    <div className="custom-scroll-nav-divider mx-2 d-flex align-items-center"></div>
                    {weekData && weekData.data && weekData.data.weeks.map((week, index) => (
                        <React.Fragment key={week.id}>
                            {index > 0 && weekData.data.weeks[index - 1].label.split('.')[0] !== week.label.split('.')[0] && (
                                <div className="custom-scroll-nav-divider mx-2 d-flex align-items-center"></div>
                            )}
                            <div className={`text-grey-200 fw-semibold text-md px-3 custom-scroll-nav-item ${
                                selectedWeek === week.label ? ' active' : ''}`}
                                 onClick={() => handleFilters(week.label)}>
                                {week.label}
                            </div>
                        </React.Fragment>
                    ))}
                </div>

            </div>
            <div className="mt-2 table-responsive-lg">
                <table className="table  custom-table bg-white table-bordered">
                    <thead>
                    <tr>
                        <th>
                            <div className="d-flex align-items-center justify-content-between">W
                                <i className="fa text-primary ms-1 fa-arrow-down-wide-short"></i></div>
                        </th>
                        <th>
                            <div className="d-flex align-items-center justify-content-between">Subject
                                <i className="fa text-ice ms-1 fa-arrow-down-wide-short"></i></div>
                        </th>
                        <th width="30%"> Topic / Unit Description</th>
                        <th style={{width: isChecked ? '26%' : '30%'}}> Learning Objectives / Key Concepts / Student
                            Outcomes
                        </th>
                        <th> Resources</th>
                        {isChecked && (
                            <th> Assessment Modes </th>
                        )}
                        <th> FRC Opportunities</th>
                    </tr>
                    </thead>
                    <tbody>

                    {selectedWeek !== 'ALL' && (
                        <tr className="bg-primary">
                            <td colSpan="11" className="text-white bg-primary text-md">
                                <div className="text-md fw-semibold px-1 d-flex align-items-center">
                                    <i className="fa fa-calendar-alt text-md fw-normal text-white me-2"></i>
                                    {eventTitle}
                                    <div className="ms-auto">Week {selectedWeek}</div>
                                </div>
                            </td>
                        </tr>
                    )}

                    {studentData && studentData.units && (
                        <>
                            {Object.values(studentSubjectsData)
                                .sort((a, b) => a.id - b.id)
                                .map((subject, index, subjectArray) => {

                                    const currentSubject = subjectArray[0].title;
                                    const nextSubject = index < subjectArray.length - 1 ? subjectArray[index + 1].title : null;
                                    const isFirstSubject = index === 0;
                                    const unitsForSubject = studentUnitData.filter((unit) => unit.subjects[0]?.id === subject?.id);
                                    const shouldDisplayRow = unitsForSubject.length > 0;
                                    const nextSubjectHasData = nextSubject && studentUnitData.filter((unit) => unit.subjects[0]?.title.toLowerCase() === nextSubject?.toLowerCase());
                                    const shouldDisplayNextSubjectRow = nextSubjectHasData && nextSubjectHasData.length>0;


                                    return (
                                        <React.Fragment key={subject.id}>
                                            {shouldDisplayRow && isFirstSubject && (
                                                <tr className="bg-secondary">
                                                    <td colSpan="11" className="text-primary text-md bg-secondary">
                                                        <div className="text-md fw-bold px-1">
                                                            {studentYear} - {currentSubject}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                            {unitsForSubject.map((unit) => (
                                                <tr key={unit.id}>
                                                    <td className="position-relative">
                                                        {getWeekInfo(unit.from_week)}
                                                        <i
                                                            className={`fa fa-calendar-alt position-absolute bottom-0 start-0 ms-2 mb-2 ${unit.event_title ? 'text-primary' : 'text-secondary'}`}
                                                            title={unit.event_title || ""}
                                                        ></i>
                                                    </td>
                                                    <td>
                                                        <div
                                                            className="text-truncate">{getSubjectName(unit?.subjects[0]?.id)}</div>
                                                    </td>
                                                    <td>
                                                        <div
                                                            className={`scrollable-cell ${isExpanded === 1 ? 'expanded' : ''}`}>
                                                            {parse(unit.topic)}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div
                                                            className={`scrollable-cell ${isExpanded === 1 ? 'expanded' : ''}`}>
                                                            {parse(unit.learning_objectives)}
                                                        </div>
                                                    </td>
                                                    <td className="">
                                                        <div className="d-flex flex-wrap">
                                                            <a href={getResourceInfo(unit.id, 'url')} target="_blank"
                                                               rel="noopener noreferrer">
                                                                <div
                                                                    className="custom-tags me-2 mb-2 d-flex align-items-center">
                                                                    {getResourceInfo(unit.id, 'title').length > 18
                                                                        ? `${getResourceInfo(unit.id, 'title').substring(0, 18)}...`
                                                                        : getResourceInfo(unit.id, 'title')}
                                                                    <i className="fa fa-arrow-up-right-from-square ms-2"></i>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </td>
                                                    {isChecked && (
                                                        <>
                                                            <td className="">
                                                                <div className="d-flex flex-wrap">
                                                                    {unit?.assessments?.map((assessment) => (
                                                                        <div
                                                                            key={assessment.id}
                                                                            className="custom-tags-assessment me-2 mb-2 d-flex align-items-center"
                                                                        >
                                                                            {assessment?.title}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </td>
                                                        </>
                                                    )}
                                                    <td>
                                                        <div>
                                                            {unit?.opportunities?.map((oppurtunity, oppurtunityIndex) => (
                                                                <span
                                                                    className="badge mb-2 me-1"
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-custom-classname="custom-tooltip"
                                                                    data-bs-placement="bottom"
                                                                    data-bs-title="To know and be able to apply a range of high-efficacy learning strategies and study skills to improve learning."
                                                                    style={{
                                                                        background: getOppurtunityInfo(oppurtunity.id, 'color'),
                                                                        border: `0.5px solid ${getOppurtunityInfo(oppurtunity.id, 'color')}`,
                                                                    }}
                                                                    key={oppurtunity.id}
                                                                >
                                                                     {getOppurtunityInfo(oppurtunity.id, 'title')}
                                                                     </span>
                                                            ))}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            {shouldDisplayNextSubjectRow  &&  (
                                                <tr className="bg-secondary">
                                                    <td colSpan="11" className="text-primary text-md bg-secondary">
                                                        <div className="text-md fw-bold px-1">
                                                            {studentYear} - {nextSubject}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                        </>
                    )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default ParentView;
