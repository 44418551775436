import React from 'react';
import logo from './../assets/images/logo.png';
import userImage from './../assets/images/user.png';

const Analytics = () => {
    return (
        <>
            <div>
                <nav className="navbar main-navbar py-0 navbar-expand-lg bg-white">
                    <div className="container-fluid">
                        <a className="navbar-brand main-logo pe-3" href="http://localhost:3000/">
                            <img src={logo} alt="Logo" />
                        </a>
                        <div className="d-lg-none d-flex justify-content-end">
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon text-primary"></span>
                            </button>
                            <div className="profile-dropdown d-flex align-items-center ps-2 ms-2 dropdown">
                                <button
                                    className="btn d-flex align-items-center  arrow-none border-0 bg-transparent p-0 dropdown-toggle"
                                    type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <div className="icon-24 rounded-circle">
                                        <img src={userImage} className="img-fluid rounded-circle" alt="user"/>
                                    </div>
                                    <i className="fa fa-chevron-down d-lg-inline d-none text-grey-200 ms-2 text-sm"></i>
                                </button>
                                <ul className="dropdown-menu dropdown-menu-start">
                                    <li><a className="dropdown-item text-md" href="http://localhost:3000/">Action</a></li>
                                    <li><a className="dropdown-item text-md" href="http://localhost:3000/">Another action</a></li>
                                    <li><a className="dropdown-item text-md" href="http://localhost:3000/">Something else here</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="collapse navbar-collapse " id="navbarTogglerDemo02">
                            <ul className="navbar-nav   mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <a href="http://localhost:3000/" className="nav-link fw-semibold text-md text-grey-200 ">
                                        My Plans
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="http://localhost:3000/" className="nav-link fw-semibold text-md text-grey-200 ">
                                        Future
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="http://localhost:3000/" className="nav-link fw-semibold text-md text-grey-200 ">
                                        Archives
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="http://localhost:3000/" className="nav-link fw-semibold text-md text-grey-200 ">
                                        All Plans
                                    </a>
                                </li>
                            </ul>
                            <ul className="navbar-nav ps-lg-2 ms-auto  mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <div className="nav-link px-md-1">
                                        <select className="custom-input custom-filter text-truncate text-teal-100">
                                            <option>Year Group</option>
                                            <option>Year Group</option>
                                        </select>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div className="nav-link  ps-lg-1 pe-lg-0">
                                        <select className="custom-input custom-filter text-truncate text-teal-100">
                                            <option>Subject</option>
                                            <option>Subject</option>
                                        </select>
                                    </div>
                                </li>
                            </ul>
                            <div className="d-lg-flex ms-xl-3 mx-lg-2 align-items-center main-navbar-right ps-lg-2">

                            <div className="d-flex align-items-center ps-2 dropdown">
                                    <button
                                        className="btn d-flex align-items-center  arrow-none btn btn-danger custom-btn dropdown-toggle"
                                        type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fa fa-home me-2"></i>
                                        Dashboard
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-start">
                                        <li><a className="dropdown-item text-md" href="http://localhost:3000/">Dashboard</a></li>
                                        <div className="dropdown-divider"></div>
                                        <li><a className="dropdown-item text-md" href="http://localhost:3000/">Analytics</a></li>
                                        <div className="dropdown-divider"></div>
                                        <li><a className="dropdown-item text-md" href="http://localhost:3000/">Admin</a></li>
                                        <div className="dropdown-divider"></div>
                                        <li><a className="dropdown-item text-md text-danger" href="http://localhost:3000/"><i
                                            className="fa fa-sign-out text-danger"></i> Logout</a></li>
                                    </ul>
                                </div>
                                <div
                                    className="profile-dropdown d-lg-flex d-none align-items-center ps-2 ms-3 dropdown">
                                    <button
                                        className="btn d-flex align-items-center  arrow-none border-0 bg-transparent p-0 dropdown-toggle"
                                        type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <div className="icon-24 rounded-circle">
                                            <img src={userImage} className="img-fluid rounded-circle" alt="user" />
                                        </div>
                                        <i className="fa fa-chevron-down text-grey-200 ms-2 text-sm"></i>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item text-md" href="http://localhost:3000/">Action</a></li>
                                        <li><a className="dropdown-item text-md" href="http://localhost:3000/">Another action</a></li>
                                        <li><a className="dropdown-item text-md" href="http://localhost:3000/">Something else here</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="bg-light py-3">
                    <ul className="nav nav-tabs main-tabs" id="myTab" role="tablist">
                        <li className="nav-item ms-md-5 ms-3" role="presentation">
                            <button
                                className="nav-link d-flex align-items-center justify-content-center fw-semibold active text-base"
                                id="home-tab" data-bs-toggle="tab"
                                data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane"
                                aria-selected="true">

                                Whole School
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link text-base d-flex align-items-center justify-content-center fw-semibold"
                                id="profile-tab" data-bs-toggle="tab"
                                data-bs-target="#profile-tab-pane" type="button" role="tab"
                                aria-controls="profile-tab-pane"
                                aria-selected="false">
                                Infant School
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link text-base d-flex align-items-center justify-content-center fw-semibold"
                                id="contact-tab" data-bs-toggle="tab"
                                data-bs-target="#contact-tab-pane" type="button" role="tab"
                                aria-controls="contact-tab-pane"
                                aria-selected="false">
                                Junior School
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link text-base d-flex align-items-center justify-content-center fw-semibold"
                                id="contact2-tab" data-bs-toggle="tab"
                                data-bs-target="#contact2-tab-pane" type="button" role="tab"
                                aria-controls="contact-tab-pane"
                                aria-selected="false">
                                Senior School
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="container-fluid px-md-5">
                    <div className="row">
                        <div className="col-lg-2 col-md-3">
                            <div className="analysis-cards mb-3">
                                <p className="text-md fw-semibold text-grey-200 mb-3">Total Plans</p>
                                <h4 className="text-primary fw-bold mb-1">59</h4>
                            </div>
                            <div className="analysis-cards mb-3">
                                <p className="text-md fw-semibold text-grey-200 mb-3">Total Units</p>
                                <h4 className="text-primary fw-bold mb-1">456</h4>
                            </div>
                            <div className="analysis-cards mb-3">
                                <p className="text-md fw-semibold text-grey-200 mb-3">Average Units</p>
                                <h4 className="text-primary fw-bold mb-1">7</h4>
                            </div>
                            <div className="analysis-cards mb-3">
                                <p className="text-md fw-semibold text-grey-200 mb-3">Active Plans</p>
                                <h4 className="text-primary fw-bold mb-1">11</h4>
                            </div>
                        </div>
                        <div className="col-lg-10 col-md-9">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="analysis-cards mb-3">
                                        <p className="text-base text-grey-200 fw-semibold">Whole School Plans with FRC
                                            competencies</p>
                                        <div style={{ height: "400px" }}>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="analysis-cards mb-3">
                                        <p className="text-base text-grey-200 fw-semibold">Whole School Plans with FRC
                                            component</p>
                                        <div style={{ height: "400px" }}>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="analysis-cards mb-3">
                                        <p className="text-base text-grey-200 fw-semibold">Whole School FRC Competencies
                                            by department</p>
                                        <div style={{ height: "400px" }}>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
);
};

export default Analytics;
