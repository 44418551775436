import React, {useEffect} from 'react';
import {Route, Routes} from 'react-router-dom';
import axios from 'axios';
import ParentView from './ParentView';
import Login from './Login';
import Analytics from './Analytics';

const NavigationHandler = () => {
    useEffect(() => {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const parent = url.searchParams.get('parent_api_data');

        if (parent) {
            const data = JSON.parse(parent);
            const token = data.token;

            localStorage.setItem('token', token);

            const axiosInstance = axios.create();

            axiosInstance.interceptors.request.use((config) => {
                const token = localStorage.getItem('token');
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
                return config;
            });
            const storedToken = localStorage.getItem('token');
            if (storedToken !== null) {
                window.location.href = '/dashboard';
            }
        }
        window.location.href = '/';
    }, []);

    return null;
};

const User = () => {
    return (
        <>
            <Routes>
                <Route path="/dashboard" element={<ParentView />} />
                <Route path="/" element={<Login />} />
                <Route path="/analytics" element={<Analytics />} />
            </Routes>
            <NavigationHandler />
        </>
    );
};

export default User;
